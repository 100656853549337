import React, {useState} from 'react'
import styled from 'styled-components'
import Burguerbutton from './Burguerbutton'

function Navbar() {
    const [clicked, setClicked]=useState(false)
    const handleClick=() =>{
        setClicked(!clicked)
    }
  return (
    <>
    <NavContainer>
        <h2>Endeavor <span>Invetsments</span></h2>
        <div className={`links ${clicked?'active':''}`}>
            <ul>
           <li> <a onClick={handleClick} href="/index.html" className="href">Inicio</a></li>
           <li> <a onClick={handleClick} href="/abot.html" className="href">Nosotros</a></li>
           <li>
             <a onClick={handleClick} href="/business.html" className="href">Negocios</a>
                <ul>
						<li><a href="">Individuales</a></li>
						<li><a href="">Institucionales</a></li>
						<li><a href="">Smart  Cash</a></li>
						<li><a href="">Wealth Management</a></li>
				</ul>
           </li>
           <li> <a onClick={handleClick} href="/contact.html" className="href">Contacto</a></li>
            </ul>
        </div>
        <div className='burguer'>
        <Burguerbutton clicked={clicked} handleClick={handleClick}/>
        </div>
        <BgDiv className={`initial ${clicked?'active':''}`}></BgDiv>
    </NavContainer>
    </>
    
 ) 
}

export default Navbar

const NavContainer = styled.nav`
 h2{
    color: white;
    font-weight: 400;
    span{font-weight: bold;}
 }
 
 padding: .4rem;
 background-color:#000;
 display:flex;
 align-items:center;
 justify-content:space-between;

 a{
    color:white;
    font-size:4rem;
    font-weight:bold;
    text-decoration:none;
    margin-right: 4rem;
 }



 .links{
    position:absolute;
    top: -1000px;
    left: -2000px;
    right:0;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    transition: all .5s ease;
    a{
        color:black;
        font-size: 2rem;
        display:block;
    }
    @media(min-width:768px){
    position:initial;
    margin:0;
    a{
        font-size:1rem; 
        color:white;
        display: inline;
    }
 }

 ul li{ @media(min-width:768px){
    list-style:none;
    display: inline-block;}
 }
ul li ul li { @media(min-width:768px){
				background-color:#000;
				color:#fff;
				text-decoration:none;
				padding:10px 12px;
				display:block;
			}}
			
li a:hover { @media(min-width:768px){
				background-color:#C30000;
                display:block;
			}}
			
li ul { 
				display:none;
				position:absolute;
				min-width:140px;
		        }
			
li:hover > ul { @media(min-width:768px){
				display:block;}
			}
				

}

.links.active{
    width:100%;
    display:block;
    position:absolute;
    top: 30%;
    left: 0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    a{
        font-size:2rem;
        margin-top:2rem;
        color: white;
    }
}  
 .burguer{
    @media(min-width:768px){
        display:none;
    }  
 }
 `





 const BgDiv = styled.div`
    position:absolute;
    background-color:black;
    top:-1000px;
    left:-2000px;
    transition: all .6s ease;
 &.active{
    border-radius: 0 0 80% 0;
     top:0%;
     left:0%;
     width:100%;
     height:100%;
     z-index:-1;
 }

 `
 
 